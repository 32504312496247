dmx.Component('link', {

  attributes: {
    internal: {
      type: Boolean,
      default: false,
    },
  },

  init (node) {
    this._clickHandler = this._clickHandler.bind(this);
    this._stateHandler = this._stateHandler.bind(this);

    node.addEventListener('click', this._clickHandler);

    if (node.classList.contains('nav-link')) {
      window.addEventListener("popstate", this._stateHandler);
      window.addEventListener("pushstate", this._stateHandler);
      window.addEventListener("replacestate", this._stateHandler);
      window.addEventListener('hashchange', this._stateHandler);
      this._stateHandler();
    }
  },

  destroy () {
    this.$node.removeEventListener('click', this._clickHandler);
    window.removeEventListener("popstate", this._stateHandler);
    window.removeEventListener("pushstate", this._stateHandler);
    window.removeEventListener("replacestate", this._stateHandler);
    window.removeEventListener('hashchange', this._stateHandler);
  },

  _navigate () {
    let url = this.$node.getAttribute('href') || '';

    if (url.startsWith('#')) {
      location.hash = url;
      return;
    }
    
    if (dmx.routing.router === 'hash') {
      url = '#!' + url;
    }
    
    const title = this.$node.title;
    history.pushState({ title: title || document.title }, '', url);
    if (title) document.title = title;
    window.dispatchEvent(new Event('pushstate'));
  },

  _clickHandler (event) {
    const url = this.$node.getAttribute('href');

    if ((this.props.internal || url.startsWith('#')) && !event.ctrlKey && event.button === 0) {
      event.preventDefault();
      this._navigate();
    }
  },

  _stateHandler () {
    const node = this.$node;
    const active = node.href == window.location.href || node.href == window.location.href.split("?")[0].split("#")[0];

    node.classList.toggle('active', active);

    if (node.classList.contains('dropdown-item')) {
      const items = node.parentNode.querySelectorAll('.dropdown-item');
      node.classList.remove('active');

      for (let i = 0; i < items.length; i++) {
        const match = items[i].href == window.location.href || items[i].href == window.location.href.split("?")[0].split("#")[0];
        if (match) {
          items[i].classList.add('active');
          node.classList.add('active');
        } else {
          items[i].classList.remove('active');
        }
      }
    }
  },

});
